$(function() {
    // Loop over all forklift containers
    $('.forklift-target').each(function( index ) {
        var fl_uri = $(this).data('fl-src');
        var fl_type = $(this).data('fl-type');
        var fl_target = $(this);
        $.get( fl_uri, function( data ) {
             fl_target.html(data);
        });
    });
});