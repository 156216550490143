(function() {

  // Find all buttons on the page.
  var submitButtons = document.querySelectorAll('button[class="item-remove-btn"], button.single-use');
  var submitButtonsSize = submitButtons.length;

  // When using browser back/forward, remove disabled attribute.
  document.body.onpageshow = function(e) {
    if (e.persisted) {
      clearDisabledButtons();
    }
  };

  // Loop through all the buttons on the page and check their submit handler.
  for (var i = 0; i < submitButtonsSize; i++) {

    submitButtons[i].onclick = function (e) {

      // disable button from being clicked multiple times.
      this.disabled = true;
      // Make sure the form still submits.
      this.form.submit();
    }
  }

  /**
   * Important: Only fires when using browser back/forward buttons.
   */
  function clearDisabledButtons() {
    for (var i = 0; i < submitButtonsSize; i++) {
      submitButtons[i].disabled = false;
    }
  }

})();
